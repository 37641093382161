@import url(./var.css);
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600&display=swap');

html {
    font-size: 62.5%;
}
  
body {
    min-height: 100vh;
    height: auto;
}

a {
    color: var(--primary-color);
}

/** grid system **/
.container {
    width: 100%;
    max-width: 1400px;
    padding: 0 16px;
    margin: 0 auto;
    position: relative;
}

.app-layout {
    min-height: 100vh;
    overflow: hidden;
}

.app-background {
    background-color: #F4F5F7;
}

.app-simple-logo {
    padding: 1.6rem 0;
    line-height: 1;
}

/* sider */
.app-aside {
    min-height: calc(100vh - 64px);
}

.app-layout .app-aside {
    position: fixed;
    left: 0;
    top: 64px;
}

.app-layout .app-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
}

.app-aside-menu {
    margin-top: 20px;
    max-height: calc(100vh - 128px);
    overflow-y: auto;
}

.app-aside-menu.ant-menu-light.ant-menu-root.ant-menu-inline,
.app-aside-menu.ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline,
.app-aside-menu.ant-menu-light.ant-menu-root.ant-menu-vertical,
.app-aside-menu.ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: none;
}

/* content */
.app-layout-content {
    padding: 2.4rem;
    /* margin-top: 64px; */
}

.app-layout .app-header + .ant-layout {
    margin-top: 64px;
    margin-left: 200px;
    overflow: auto;
    transition: margin .3s ;
}

.app-layout .app-header + .ant-layout:has(.ant-layout-sider-collapsed){
    margin-left: 60px;
}

/* Footer */
.app-footer {
    text-align: center;
    font-size: 12px;
}

/* Header */
.app-header {
    box-shadow: inset 2px 0px 3px rgba(0, 0, 0, 0.03), 0px 2px 3px rgba(0, 0, 0, 0.03);
    transition: all 0.3s;
}

.app-header-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    position: relative;
}

.app-header-left {
    display: flex;
}

.app-header-logo .app-header-logo-text {
    margin-left: 1rem;
}

.app-header-menu {
    background: transparent;
    border: none;
    margin-left: 24px;
}

.app-header-menu .ant-menu-item {
    padding: 0 15px;
    margin: 0 4px;
}

.app-header-menu .ant-menu-item:hover {
    color: var(--ant-primary-color);
}

.app-header-menu .ant-menu-item-selected {
    background-color: var(--ant-primary-1);
}

.app-header-menu .ant-menu-item-selected:hover {
    background-color: var(--ant-primary-1);
}

.app-header-right {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.app-header-right li + li {
    margin-left: 0.8rem;
}

.app-header-right .user {
    cursor: pointer;
}

.app-header-right .user .name {
    margin-left: 0.8rem;
}

.app-header-mobile-menu {
    display: none;
    position: relative;
    width: 26px;
    /* height: 20px; */
    cursor: pointer;
    z-index: 100;
}

.app-header-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: var(--text-color-light-8);
    margin-top: 6px;
    transition: all 0.3s;
    transition-delay: 0.2s;
}

.app-header-mobile-menu em:first-child {
    margin-top: 0;
}

.header-menu {
    position: fixed;
    top: 64px;
    right: 0;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 99;
    border-radius: 0 0 4px 4px;
}

.header-menu .mobile-menu {
    border: none;
}

.dark .header-menu {
    background-color: #141414;
}

.dark .header-menu .ant-menu {
    background-color: #141414;
}

/** 登入頁 **/
.app-login {
    min-height: 100vh;
    background-color: #00202b;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.app-login-form {
    max-width: 380px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    height: calc(100vh - 160px);
}

.app-login-form .ant-btn-lg.ant-btn-loading {
    opacity: 1;
}

.app-login-btn-ghost {
    background: #fff;
    color:rgba(0,0,0,0.85);
    box-shadow:0 0 15px rgba(0,0,0,0.12);
}

.app-login-btn-ghost:hover, .app-login-btn-ghost:focus {
    background: #fff !important;
}

.app-login-form .ant-btn.ant-btn-background-ghost:hover {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
}

.app-login-form h1 {
    text-align: center;
    margin-bottom: 40px;
    color: rgba(255,255,255,0.8);
    line-height: 1.57;
}

.app-login-form .logo {
    margin-bottom: 80px;
}

.app-login-form .ant-checkbox-wrapper {
    color: rgba(255,255,255,0.8);
}

.app-login-form .ant-input,
.app-login-form .ant-input-affix-wrapper {
    background-color: transparent;
    border: 1px solid rgba(255,255,255,0.2);
    color: rgba(255,255,255,0.8);
}

.app-login-form .ant-input-affix-wrapper > .ant-input {
    border: none;
}

.app-login-form .login-form-forgot {
    float: right;
    color: #009CCD;
}

.app-login-form .ant-input-password-icon {
    color: rgba(255,255,255,0.6);
}

.app-login-form .ant-input-password-icon:hover {
    color: rgba(255,255,255,0.8);
}



.app-login-footer {
    align-self: flex-end;
    text-align: center;
    color: rgb(255 255 255 / 30%);
    width: 100%;
}

.app-login-form .ant-checkbox-wrapper {
    color: rgba(255, 255, 255, 0.8);
}

.ant-layout-sider-collapsed .logo-container {
    padding: 16px;
    text-align: center;
}

.logo-container {
    padding: 24px 16px;
    transition: 0.3s;
}

.logo-container > img {
    max-width: 100%;
}

.stage-name {
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 1.57;
}

.app-login-form h1 + .stage-name {
    margin-top: -40px;
    margin-bottom: 40px;
}

.app-login-header-menu .ant-btn.ant-btn-background-ghost,
.app-login-form .ant-btn.ant-btn-background-ghost{
    color: var(--text-color-light-8) !important;
    border-color: var(--text-color-light-4) !important;
}

.app-login-header-menu .ant-btn.ant-btn-background-ghost:hover {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.app-login-header-menu .ant-btn-link:hover {
    color: var(--primary-color) !important;
}

/* select-menu */
.app-select-menu-item {
    text-align: center;
    padding: 2.4rem 1.2rem;
    cursor: pointer;
    transition: .3s;
}

.app-select-menu-item.disabled {
    color: var(--text-color-light-2);
    cursor: not-allowed;
}

.app-select-menu-item.disabled .icon{
    color: var(--text-color-light-2)
}

.app-select-menu-item:hover {
    transform: translateY(-6px);
}

.app-select-menu-item.disabled:hover {
    transform:none
}

.app-select-menu-item .icon {
    font-size: 3rem;
    color: var(--primary-color);
}

.app-select-menu-item .description {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

/* app-page-title */
.app-page-title {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

/* app-page-search */
.app-page-search {
    margin-top: 2rem;
}

/* app-page-filter */
.app-page-filter {
    margin-top: 2rem;
    width: 100%;
}

/* app-page-body */
.app-page-body {
    margin-top: 2rem;
}

/* loading */
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 50px);
}

.loading-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    height: 100%;
}
.wave {
    width: 4px;
    height: 20px;
    background: rgba(155, 155, 155, 0.4);
    margin: 1px;
    animation: wave 0.8s linear infinite;
    border-radius: 10px;
}
.wave:nth-child(2) {
    animation-delay: -0.1s;
}
.wave:nth-child(3) {
    animation-delay: -0.2s;
}
.wave:nth-child(4) {
    animation-delay: -0.3s;
}
.wave:nth-child(5) {
    animation-delay: -0.4s;
}
.wave:nth-child(6) {
    animation-delay: -0.5s;
}
.wave:nth-child(7) {
    animation-delay: -0.6s;
}
.wave:nth-child(8) {
    animation-delay: -0.7s;
}
.wave:nth-child(9) {
    animation-delay: -0.8s;
}
.wave:nth-child(10) {
    animation-delay: -0.9s;
}

@keyframes wave {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/** 404 **/
.notfound {
    text-align: center;
    padding-top: 4rem;
}

.notfound-img {
    width: 38vw;
    opacity: 0.6;
    margin: 0 auto;
}
  
.notfound-text {
    text-align: center;
}

.notfound-text h1 {
    font-size: 4.8rem;
    margin-bottom: 1rem;
}

.notfound-text p {
    margin-bottom: 3rem;
}

/* 系統設定 */
.list-item {
    display: flex;
    justify-content: space-between;
}

.list-item + .list-item {
    margin-top: 20px;
}

.list-item .list-title {
    display: flex;
    align-items: center;
}

.lockman {
    position: absolute;
    right: 0;
    bottom: 1vh;
    z-index: 0;
}

.lockman img {
    opacity: 0.6;
    width: 28vw;
}

/* link */
.app-table-link {
    display: inline-block;
    color: rgba(0, 0, 0, 0.85) !important;
    width: 100%; 
    max-width: 450px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.ant-table-wrapper.table-loading .ant-table-empty {
    display: none;
}

.ant-table-wrapper:has(.ant-empty) + .loading-animation > .metadata {
    display: none;
}

.filter-separater {
    color: rgba(0, 0, 0, 0.4);
    padding: 0 8px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* tooltip */
.ant-tooltip {
    max-width: 800px;
}

/* 修改Chrome浏览器的滚动条样式 */
::-webkit-scrollbar {
    width: 7px; /* 设置滚动条宽度 */
    height: 7px;
    border-radius: 6px;
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
    background-color: rgb(155 155 155 / 50%); /* 设置滑块颜色 */
    border-radius: 6px;
}

/* 滚动条背景样式 */
::-webkit-scrollbar-track {
    background-color: transparent; /* 设置背景颜色 */
    border-radius: 6px;
}

/* 修改Edge浏览器的滚动条样式 */
::-ms-scrollbar {
    width: 6px; /* 设置滚动条宽度 */
    border-radius: 6px;
}

/* 滚动条滑块样式 */
::-ms-thumb {
    background-color: transparent; /* 设置滑块颜色 */
    border-radius: 6px;
}

/* 滚动条背景样式 */
::-ms-track {
    background-color: transparent; /* 设置背景颜色 */
    border-radius: 6px;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

/* tooltip路徑 */
.app-tooltip-path {
    display: flex;
    padding: 4px;
}

.app-tooltip-path a {
    color: rgba(255, 255, 255, 0.85);
    max-width: 200px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.app-tooltip-path a + a::before {
    display: inline-block;
    content: '>';
    color: rgba(255, 255, 255, 0.3);
    padding: 0 8px;
    cursor: auto;
}

.ant-layout .ant-layout-sider-trigger {
    color: rgba(0, 0, 0, 0.4);
}

.app-card-task-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.app-card-task-list li {
    color: rgba(0, 0, 0, 0.45);
    line-height: 1.9;
}

.app-card-task-list li > span {
    color: rgba(0, 0, 0, 0.85);
}

.app-card-task-list-action {
    margin-top: 20px;
    width: 100%;
}

.picker-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1000;
    pointer-events: auto; 
  }
  
  .picker-modal {
    background-color: var(--picker-modal-bg);
    width: 100%;
    max-width: 400px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    pointer-events: auto; 
  }
  
  .picker-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid var(--ant-color-border);
    /* background-color: #272E36; */
}

.picker-form-input::placeholder {
    color: #fff; /* Change to your desired color */
}

/* 使用者 */
.list-user-item {
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 8px;
}

.list-user-item-title {
    color: rgba(0, 0, 0, 0.65);
}

.list-user-item-value {
    font-size: 16px;
}

.app-tab-label {
    display: flex;
    gap: 6px;
}

.app-page-body .ant-tabs-nav{
    margin-bottom: 0px;
}

.app-spin .ant-spin {
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-spin.ant-spin-nested-loading .ant-spin-container::after {
    background: transparent;
}

.ant-tabs-top >.ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: inset -10px 0 12px -8px rgba(0, 0, 0, 0.2)
}