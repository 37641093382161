@import url(./var.css);

@media (min-resolution: 144dpi) and (min-width: 992px) {
    .index-numbers-block .number span,
    .index-status-small .text p span {
      font-size: 1.8rem !important;
    }
}
  
@media (max-width: 1440px) {
    .index-status-small .chart {
      display: none;
    }
  
    .index-numbers-block .number span {
      font-size: 2rem;
    }
  
    .alert-list-items {
      display: none;
    }
}
  
@media (max-width: 1200px) {
    .lockman {
      display: none;
    }
  
    .index-first-topology label {
        display: none;
    }
  
    .time-zone {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  
    .time-slider {
      width: 100%;
    }
  
    .index-first-select-end {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
}
  
@media (max-width: 991px) {
    .index-first-date {
      display: none;
    }
}
  
@media (max-width: 767px) {
    .app-layout .app-header + .ant-layout,
    .app-layout .app-header + .ant-layout:has(.ant-layout-sider-collapsed) {
        margin-left: 0px;
    }

    .app-page-search {
      flex-direction: column;
      align-items: flex-start
    }
    
    .app-page-search .ant-checkbox-wrapper {
      margin-left: 0 !important;
      margin-top: 16px;
    }

    .index-section-title + .ant-btn {
      display: none !important;
    }
      
    .notfound-img {
      width: 70vw;
    }
  
    .index-first-date {
      text-align: left;
    }
  
    .app-header-note {
      display: none;
    }
  
    .ant-layout-sider-collapsed + .ant-layout .app-footer {
      margin-left: 0;
    }
  
    .app-header {
      margin: 0;
      border-radius: 0;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
      border-left: 0;
      z-index: 1001 !important;
    }
  
    .app-content {
      margin: 9.6rem 1.6rem 0 1.6rem;
    }
  
    /** sidebar collapsed **/
    .ant-layout-sider-collapsed + .ant-layout .app-content {
      margin: 8.8rem 2rem 0 2rem;
    }
  
    .ant-layout-sider-collapsed + .ant-layout .app-header {
      margin: 0;
    }
  
    .app-login-form {
      margin: 0 auto;
    }
  
    .app-login-header .menu {
      display: none;
    }
  
    .ant-layout-header {
      padding: 0 20px;
      height: 64px;
      line-height: 64px;
    }
  
    .loading {
      margin-left: 0;
    }
  
    .app-aside {
      display: none;
    }
  
    .app-header-logo {
      display: block;
    }
  
    .app-header-right-menu .user {
      display: none;
    }
  
    .app-header-mobile-menu {
      display: block;
    }

    .app-header-menu {
      display: none;
    }

    .app-header-right {
      display: none;
    }

    .header-menu {
      width: 100%;
      height: calc(100vh - 64px);
      overflow-y: auto;
      z-index: 1001;
    }

    .header-menu .mobile-menu {
      width: 100%;
      padding: 16px;
    }

    .app-header-container {
      padding: 0 16px;
    }

    .app-header-mobile-menu em {
        display: block;
        width: 100%;
        height: 2px;
        background: var(--text-color-light-8);
        margin-top: 6px;
        transition: all 0.3s;
        transition-delay: 0.2s;
    }
  
    .app-header-mobile-menu:first-child {
        margin-top: 0;
    }
  
    .app-header-mobile-menu.open {
        height: auto;
    }
    .app-header-mobile-menu.open em {
        background-color: #fff !important;
    }
    .app-header-mobile-menu.open em:nth-child(1) {
        transform: translateY(4px) rotate(45deg);
    }
  
    .app-header-mobile-menu.open em:nth-child(2) {
        transform: translateY(-4px) rotate(-45deg);
    } 
  
    .app-header-mobile-menu.open + .header-menu {
        transform: translateX(0%);
    }

    .app-header-mobile-menu.open + .header-menu .header-rightline {
        display: none;
    }

    .app-header-mobile-menu.open + .header-menu li::after {
        transform: scaleX(100%);
    }

    .filter-separater {
      display: none;
    }
}
  
@media (max-width: 575px) {
  .index-section-title {
    margin-bottom: 0.8rem;
  }

  .index-money-saving-text {
    font-size: 1.6rem;
  }

  .app-card-actionItems {
    flex-direction: column;
    align-items: flex-end;
  }

  .app-device-subtitle {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .app-device-subtitle .ant-divider {
      display: none;
  }

  .app-form-list .ant-descriptions-item-container{
      flex-direction: column;
  }

  .app-device-chart {
    height: 220px;
  }

  .app-descriptions .ant-descriptions-item-container {
    flex-direction: column;
  }

  .app-descriptions .ant-descriptions-item-label {
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .ant-tooltip {
    max-width: 100%;
  }

  .container {
    padding: 0;
  }

  .app-login-content .container {
    padding: 0 16px;
  }
}
  